import apiClient from './apiClient'

const BASE_URL = window.app_url
export default {
    create_event(event_type, message, has_modification = false) {
        if (!window.browser_id) return

        //fire off the google analytics event
        this.ga_event(event_type, message)
        return apiClient
            .post('/event', {
                event_type: event_type,
                event_page: window.location.pathname,
                referrer: document.referrer,
                params: window.location.search,
                message: message ? message : '',
                modified: has_modification,
            })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                apiClient
                    .post('/error', {
                        url: window.location.pathname,
                        message: error.message,
                        line: error.lineNumber,
                        column: error.columnNumber,
                        stack: error.stack,
                    })
                    .then((response) => {
                        if (response.data.status === 'error') {
                            console.error(response.data)
                        }
                    })
                    .catch(() => {
                        console.error('Unable to register captured error')
                    })
            })
    },
    send_beacon(event_type, message, has_modification = false) {
        if (navigator.sendBeacon) {
            let data = new FormData()
            data.append('event_type', event_type)
            data.append('event_page', window.location.pathname)
            data.append('referrer', document.referrer)
            data.append('params', window.location.search)
            data.append('message', message ? message : '')
            data.append('modified', has_modification)

            return navigator.sendBeacon(BASE_URL + '/event', data)
        } else {
            //sendBeacon isn't supported. must be an old browser, ttry to send the standard ajax
            this.create_event(event_type, message, has_modification)
        }
    },
    ga_event(event_type, message) {
        let evt = {
            event_category: 'ecommerce',
            event_label: message,
        }
        let engagement = [
            'leaving',
            'log-in-view-worksheet-rating',
            'login-worksheet-toolbar',
            'set_worksheet_type-bingo',
            'set_worksheet_type-fill_in_the_blank',
            'set_worksheet_type-flashcards',
            'set_worksheet_type-handwriting',
            'set_worksheet_type-matching',
            'set_worksheet_type-multiple_choice',
            'set_worksheet_type-word_scramble',
            'set_worksheet_type-open_response',
            'print',
            'sign-up-worksheet-toolbar',
        ]
        if (engagement.includes(event_type)) {
            evt.event_category = 'engagement'
        }

        gtag('event', event_type, evt)
    },
}
