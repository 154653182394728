import _ from 'lodash'

export const findObjectById = (id, list) => {
    let object = list.filter(function (ele) {
        return ele.id === id
    })

    if (Array.isArray(object)) {
        return object[0]
    }
    return object
}

function ObjectLength_Modern(object) {
    if (object) return Object.keys(object).length

    return 0
}

function ObjectLength_Legacy(object) {
    var length = 0
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            ++length
        }
    }
    return length
}

const objectLength = () => {
    return Object.keys ? ObjectLength_Modern : ObjectLength_Legacy
}

const isObject = (objValue) => {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object
}

//Check if images array has uploaded images(Non Icon images)
export const hasNonIconImages = (list) => {
    let nonIcon = false
    if (_.isUndefined(list)) {
        return nonIcon
    }

    for (let i = 0; i < list.length; i++) {
        if (!list[i].is_icon) {
            nonIcon = true
            break
        }
    }

    return nonIcon
}

//Check if images array has uploaded images(Non Icon images)
export const hasIconImages = (list) => {
    let hasIcon = false
    if (_.isUndefined(list) || !list) {
        return hasIcon
    }

    for (let i = 0; i < list.length; i++) {
        if (list[i].is_icon) {
            hasIcon = true
            break
        }
    }

    return hasIcon
}

//Check if images array has uploaded images(Non Icon images)
export const iconImages = (list) => {
    if (_.isUndefined(list) || !list) {
        return []
    }
    let icons = []

    list.forEach((item) => {
        if (item.is_icon) {
            icons.push(item)
        }
    })

    return icons
}

export const getUndownloadedIcons = (list) => {
    if (_.isUndefined(list)) {
        return
    }
    let icons = []

    list.forEach((item) => {
        if (!item.downloaded) {
            icons.push(item)
        }
    })

    return icons
}

//Check if list of images array has premium Icon images)
export const hasPremiumIcon = (list) => {
    let premium = false
    if (_.isUndefined(list)) {
        return premium
    }

    list.forEach((item) => {
        if (item.is_icon && item.iconObject && (item.iconObject.is_premium || item.iconObject.our_premium)) {
            premium = true
        }
    })

    return premium
}

//Check if list of images array has premium Icon images)
export const freeIcons = (list) => {
    if (_.isUndefined(list)) {
        return []
    }

    return list.filter((item) => item.is_icon && !item.iconObject.is_premium)
}

export class IconCategoryObject {
    constructor(item) {
        this.identifier = item.identifier
        this.name = item.name
    }
}
