import { PlanType } from '../objects/Plan'

export interface PlanFeature {
    text: string
    isEnabled: boolean
}

export const planFeatureConfig = {
    unlimitedWorksheets: 'Unlimited Worksheets',
    unlimitedBingoCards: 'Unlimited Bingo Cards',
    unlimitedFlashcards: 'Unlimited Flashcards',
    noAdPledge: 'No-Ad Pledge',
    humanSupport: 'Human Support',
    unlimitedEdits: 'Unlimited Edits',
    saveAsPrivate: "Save as 'Private'",
    uploadImages: 'Upload Images',
    premiumImages: 'Premium Images',
    organizeInFolders: 'Organize in Folders',
    allFormatOptions: 'All Format Options',
    printwithoutLogo: 'Print without Logo',
    unlimitedCrosswords: 'Unlimited Crosswords',
    unlimitedWordSearches: 'Unlimited Word Searches',
    lifetimeMembership: 'Lifetime Membership',
}

export enum SocialLink {
    twitter = 'twitter',
    facebook = 'facebook',
}

export enum ApplicationLink {
    faq = 'faq',
    myProfile = 'myProfile',
    sisterSites = 'sisterSites',
}

export default interface Config {
    name: string
    alias?: string
    planFeatures?: Record<PlanType, PlanFeature[]>
    socialLinks: Record<SocialLink, string>
    applicationLinks: Record<ApplicationLink, string>
}
