import { default_color, default_font, defaultInlineImagesSettings } from '../helpers/documentHelpers'

export default class Style {
    constructor(type = null) {
        this.font_size = type != null && (type == 'bingo' || type == 'flashcard') ? 24 : 14
        this.font = default_font
        this.color = default_color
        this.line_height = 33.3331
        this.font_space = 1.3
        this.item_spacing = 2
        this.border_style = 'solid'
        this.border_color = default_color
        this.border_width = 2
        this.guide_line_color = default_color
        this.number_format = 1
        this.images = { ...defaultInlineImagesSettings }
        this.images.size = type != null && type === 'worksheet' ? 50 : this.images.size
    }
}
