import BingoItem from './BingoItem'
import DocumentItem from './DocumentItem'
import HandWritingItem from './HandWritingItem'
import Numbering from './Numbering'
import OpenResponseItem from './OpenResponseItem'
import WordbankItem from './WordbankItem'
import MultipleChoiceItem from './MultipleChoiceItem'
import InstructionItem from './InstructionItem'
import BasicTextItem from './BasicTextItem'

import DividerItem from './DividerItem'
import BlankSpaceItem from './BlankSpaceItem'
import SectionHeaderItem from './SectionHeaderItem'
import FillInTheBlankItem from './FillInTheBlankItem'
import WordScrambleItem from './WordScrambleItem'
import MatchingDataModel from './MatchingDataModel'
import Style from './Style'

import uuidv1 from 'uuid/v1'
import ChecklistItem from './ChecklistItem'

export default class Activity {
    constructor(type) {
        this.id = uuidv1()
        this.hide = false
        this.display_order = 0
        this.type = type
        this.style = new Style()
        this.data = this.createDocumentItem(type)
        this.numberable = [
            'open_response',
            'multiple_choice',
            'checklist',
            'fill_in_the_blank',
            'word_scramble',
            'bingo',
            'flashcard',
        ].includes(type)
        this.numbering = this.numberable ? new Numbering() : undefined
    }

    static parseFromObject({ id, hide, display_order, type, style, data, numberable, numbering }) {
        const activity = new Activity(type)
        activity.id = id
        activity.hide = hide
        activity.display_order = display_order
        activity.type = type
        activity.style = Object.assign({}, activity.style, style)
        activity.data = Object.assign(activity.data, data)
        activity.numberable = numberable
        activity.numbering = numbering && Object.assign({}, activity.numbering, numbering)

        if (type === 'handwriting' && (data.version || 0) < 2) {
            activity.data.migrateItem()
        }

        return activity
    }

    createDocumentItem(type) {
        switch (type) {
            case 'matching':
                return new MatchingDataModel()
            case 'handwriting':
                return new HandWritingItem()
            case 'open_response':
                return new OpenResponseItem()
            case 'bingo':
                return new BingoItem()
            case 'word_bank':
                return new WordbankItem()
            case 'multiple_choice':
                return new MultipleChoiceItem()
            case 'checklist':
                return new ChecklistItem()
            case 'blank_space':
                return new BlankSpaceItem()
            case 'instruction':
                this.style.border_width = 1
                return new InstructionItem()
            case 'basic_text':
                this.style.border_width = 1
                return new BasicTextItem()
            case 'divider':
                return new DividerItem()
            case 'section_header':
                return new SectionHeaderItem()
            case 'fill_in_the_blank':
                return new FillInTheBlankItem()
            case 'word_scramble':
                return new WordScrambleItem()
            default:
                return new DocumentItem()
        }
    }

    getWordBankColor() {
        return this.data.wordbank_color
    }

    getSubtitle() {
        return this.data.subtitle
    }

    getItem() {
        return this.data
    }

    toggleHide() {
        this.hide = !this.hide
    }

    update(data) {
        this.data = data
    }

    clone() {
        const newOne = Object.assign(Object.create(Object.getPrototypeOf(this)), JSON.parse(JSON.stringify(this)))
        if (newOne.numbering) {
            newOne.numbering.numbering_restarted = false
            newOne.numbering.hidden = false
        }
        return newOne
    }

    copy() {
        const newActivity = this.clone()
        if (this.numberable) {
            newActivity.numbering.format = this.numbering.format
        }

        return newActivity
    }

    getNextOrder(activity) {
        if (activity && activity.numberable) {
            const order = activity?.numbering?.number || 0
            return order + 1
        }

        return 1
    }

    setItemOrder(prevActivity) {
        this.numbering.number = this.getNextOrder(prevActivity)
    }

    increaseOrder() {
        this.display_order = this.display_order + 1
    }

    decreaseOrder() {
        this.display_order = this.display_order - 1
    }

    exchangeOrder(target) {
        if (!target) return

        const tmp = this.numbering.number
        this.numbering.number = target.numbering.number
        target.numbering.number = tmp
    }

    copyStyleFrom(item) {
        if (this.type !== 'instruction') {
            this.style = Object.assign({}, item?.style)
        }
    }
}
