import DocumentItem from './DocumentItem'

export default class FillInTheBlankItem extends DocumentItem {
    constructor() {
        super()

        this.type = 'whole_word'
        this.words = []
        this.choices = [
            {
                answer: '',
                correct: false,
            },
            {
                answer: '',
                correct: false,
            },
        ]
        this.answer_type = 'blank'
        this.number_of_columns = 1
        this.letter_blanks = false
        this.shuffle_seed = 0
        this.include_wordbank = 0
        this.blank_style = 'underline'
        this.wordbank_id = undefined
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'fill_in_the_blank':
                this.type = item.data.type
                this.answer_type = item.data.answer_type
                this.number_of_columns = item.data.number_of_columns
                this.letter_blanks = item.data.letter_blanks
                this.shuffle_seed = item.data.shuffle_seed
                this.include_wordbank = item.data.include_wordbank
                this.blank_style = item.data.blank_style
                this.wordbank_id = item.data.wordbank_id

            case 'word_scramble':
                this.include_wordbank = item.data.include_wordbank
                this.wordbank_id = item.data.wordbank_id

            default:
                break
        }
    }
}
