import { basicFonts, premiumFonts } from '../../../objects/Document'
import { Fonts } from '../../../repos/Fonts'
import { default_font } from '../../helpers/documentHelpers'
import { freeIcons, hasNonIconImages, hasPremiumIcon } from '../../../helpers/jsonObjectHelper'
import BaseModule from '../base'
import EventApi from '../../../apis/EventApi'

export default class PremiumModule extends BaseModule {
    getters() {
        return {
            ...super.getters(),
            handwritingFonts: (state, getters) => {
                return getters.documentItems
                    .filter((i) => i.type === 'handwriting')
                    .flatMap((hw) => [hw.data?.hw_font_print, hw.data?.hw_font_cursive])
            },
            getPremiumFonts: (state, getters, rootState) => {
                // If 'one' return default_font
                const pFonts = []
                const premiumFontType = rootState.abtests.premium_fonts

                if (premiumFontType == 'one') {
                    for (let i = 0; i < Fonts.fonts.length; i++) {
                        if (Fonts.fonts[i] !== default_font) {
                            pFonts.push(Fonts.fonts[i])
                        }
                    }
                    return pFonts
                }

                // If 'basic' return common fonts
                if (premiumFontType == 'basic') {
                    for (let i = 0; i < Fonts.fonts.length; i++) {
                        if (!basicFonts.includes(Fonts.fonts[i])) {
                            pFonts.push(Fonts.fonts[i])
                        }
                    }
                    return pFonts
                }

                // If 'most' we have some premium fancy fonts
                if (premiumFontType == 'most') {
                    return premiumFonts
                }

                // If 'all' there are no premium fonts
                if (premiumFontType == 'all') {
                    return []
                }
            },
            getPremiumFeatures: (state, getters, rootState, rootGetters) => {
                const documentEntityType = rootGetters['document/entityType']
                const documentType = getters.getDocumentType
                const wordbank = getters.wordbank
                const style = getters.worksheetStyle
                const wordbankStyle = getters.wordbankStyle
                const instruction = getters.documentInstructions
                const handwritingFonts = getters.handwritingFonts
                const images = rootGetters[`document/${documentEntityType}AllImages`] ?? []
                let premiumFonts = getters.getPremiumFonts
                const premiumFeatures = []

                // Numbers
                if (documentEntityType == 'worksheet') {
                    const worksheet = rootGetters['document/worksheet']
                    let hasPremiumNumbers = false
                    for (let i in worksheet.body.items) {
                        if (worksheet.body.items[i].numbering?.format > 2) {
                            hasPremiumNumbers = true
                        }
                    }
                    if (hasPremiumNumbers) {
                        premiumFeatures.push('numbering')
                    }
                }

                // Images
                if (images.length > 0 && hasNonIconImages(images)) {
                    premiumFeatures.push('image_uploading')
                }

                // Images
                if (hasPremiumIcon(images)) {
                    premiumFeatures.push('premium_icons')
                }

                // Fonts
                if (premiumFonts) {
                    if (
                        (state.title_visible && premiumFonts.indexOf(state.title_font) > -1) ||
                        (state.student_info_visible && premiumFonts.indexOf(state.student_info_font) > -1) ||
                        (instruction && instruction.visible && premiumFonts.indexOf(instruction.style.font) > -1) ||
                        (documentType != 'handwriting' && premiumFonts.indexOf(style.font) > -1) ||
                        (wordbank?.visible &&
                            (['fill_in_the_blank', 'word_scramble'].includes(documentType) ||
                                state.entity_type === 'bingo') &&
                            premiumFonts.indexOf(wordbankStyle.font) > -1)
                    ) {
                        //we have premium fonts and they're in use
                        premiumFeatures.push('font')
                    }
                }

                // Handwriting Fonts
                if (
                    premiumFonts &&
                    handwritingFonts.length &&
                    handwritingFonts.filter((p) => premiumFonts.includes(p)).length > 0
                ) {
                    //we have premium fonts and they're in use
                    premiumFeatures.push('hw_font')
                }

                //borders
                if (
                    (instruction && instruction.visible && instruction.style.border_style != 'solid') ||
                    (wordbankStyle?.visible &&
                        wordbankStyle?.border_style != 'solid' &&
                        ['fill_in_the_blank', 'word_scramble'].includes(documentType))
                ) {
                    premiumFeatures.push('border') //we have premium fonts and they're in use
                }

                // Number format cannot be ) or FALSE
                if (style.number_format > 2 && documentType != 'handwriting' && documentType != 'bingo') {
                    premiumFeatures.push('number')
                }

                //Check Bingo card count
                if (documentType == 'bingo' && state.bingo.num_of_cards > rootState.user.ab_tests['premium-bingo-cards']) {
                    premiumFeatures.push('card_count')
                }

                //fill-in-the-blank blank style
                const worksheetItems = state.worksheet.body.items
                for (let wItem of worksheetItems) {
                    if (wItem.type == 'fill_in_the_blank' && wItem.data.blank_style != 'underline') {
                        premiumFeatures.push('fill_in_the_blank_style')
                    }
                }

                if (!rootState.subscription.name) {
                    if (state.is_private == '1') {
                        premiumFeatures.push('private')
                    }
                } else {
                    if (
                        !rootState.subscription.name.includes('Unlimited Worksheets') &&
                        !rootState.subscription.name.includes('Private Worksheets') &&
                        state.is_private == '1'
                    ) {
                        premiumFeatures.push('private')
                    }
                }

                if (rootState.subscription.name && rootState.subscription.name.includes('Unlimited Worksheets')) {
                    return []
                }
                return premiumFeatures
            },
        }
    }

    actions() {
        return {
            ...super.actions(),
            getPremiumFeaturesByDocument({ rootState, getters }, document) {
                const premiumFeatures = []
                const documentEntityType = document.entity_type
                const handwritingFonts = document?.body?.items
                    .filter((i) => i.type === 'handwriting')
                    .flatMap((hw) => [hw.data?.hw_font_print, hw.data?.hw_font_cursive])
                const premiumFonts = getters.getPremiumFonts
                // const style = document.worksheetStyle
                let instructions,
                    wordbanks,
                    documentItems = []

                // Numbers
                if (documentEntityType == 'worksheet') {
                    let hasPremiumNumbers = false
                    documentItems = document.entity.body.items
                    for (let i in documentItems) {
                        if (documentItems[i].numbering?.format > 2) {
                            hasPremiumNumbers = true
                        }
                    }
                    if (hasPremiumNumbers) {
                        premiumFeatures.push('numbering')
                    }

                    instructions = documentItems.filter((docItem) => docItem.type === 'instruction')

                    wordbanks = documentItems.filter((docItem) => docItem.type === 'wordbank')
                } else {
                    documentItems = document.entity.content.items
                    instructions = [document.entity.instruction]
                    wordbanks = [document.entity.content.wordbank]
                }

                const instructionFonts = instructions.filter((i) => !i.hide).map((i) => i.style.font)
                const wordbankFonts = wordbanks.map((i) => i?.style?.font)

                // Fonts
                if (premiumFonts) {
                    if (
                        (document.title_visible && premiumFonts.indexOf(document.title_font) > -1) ||
                        (document.student_info_visible && premiumFonts.indexOf(document.student_info_font) > -1) ||
                        (instructionFonts.length && instructionFonts.some((iFont) => premiumFonts.includes(iFont))) ||
                        (wordbankFonts?.length && wordbankFonts.some((iFont) => premiumFonts.includes(iFont)))
                    ) {
                        //we have premium fonts and they're in use
                        premiumFeatures.push('font')
                    }
                }

                // Handwriting Fonts
                if (
                    premiumFonts &&
                    handwritingFonts &&
                    handwritingFonts.length &&
                    handwritingFonts.filter((p) => premiumFonts.includes(p)).length > 0
                ) {
                    //we have premium fonts and they're in use
                    premiumFeatures.push('hw_font')
                }

                //borders
                const borderStyles = [...instructions, ...wordbanks].map((i) => i?.style?.border_style)
                if (borderStyles.some((borderStyle) => borderStyle && borderStyle !== 'solid')) {
                    premiumFeatures.push('border') //we have premium fonts and they're in use
                }

                // Number format cannot be ) or FALSE
                if (document.number_format > 2 && documentType != 'handwriting' && documentType != 'bingo') {
                    premiumFeatures.push('number')
                }

                //Check Bingo card count
                if (
                    documentEntityType == 'bingo' &&
                    document.num_of_cards > rootState.user.ab_tests['premium-bingo-cards']
                ) {
                    premiumFeatures.push('card_count')
                }

                //fill-in-the-blank blank style
                for (let wItem of documentItems) {
                    if (wItem.type == 'fill_in_the_blank' && wItem.data.blank_style != 'underline') {
                        premiumFeatures.push('fill_in_the_blank_style')
                    }
                }

                if (documentEntityType !== 'worksheet') {
                    return []
                }

                if (!rootState.subscription.name) {
                    if (document.is_private == 1) {
                        premiumFeatures.push('private')
                    }
                } else {
                    if (
                        !rootState.subscription.name.includes('Unlimited Worksheets') &&
                        !rootState.subscription.name.includes('Private Worksheets') &&
                        document.is_private == 1
                    ) {
                        premiumFeatures.push('private')
                    }
                }

                if (rootState.subscription.name && rootState.subscription.name.includes('Unlimited Worksheets')) {
                    return []
                }
                return premiumFeatures
            },
            removePremiumFeatures({ state, rootState, dispatch, commit, getters, rootGetters }) {
                commit('SET_IS_LOADING', false)

                let documentUpdates = {
                    is_private: 0,
                }
                let wordbankStyleUpdate = {
                    border_style: 'solid',
                }
                let documentInstructionUpdates = {
                    border_style: 'solid',
                }
                let worksheetSettingUpdates = {}
                let documentStyleUpdates = {}

                // Numbers
                const documentEntityType = rootGetters['document/entityType']
                if (documentEntityType == 'worksheet') {
                    const worksheet = rootGetters['document/worksheet']

                    for (let item of worksheet.body.items) {
                        if (item.numbering?.format > 2) {
                            item.numbering.format = 2
                        }
                    }
                }

                // Check premium fonts
                let premiumFonts = getters.getPremiumFonts
                const documentStyle = getters.documentStyle
                const wordbankStyle = getters.wordbankStyle

                if (premiumFonts.includes(documentStyle.font)) {
                    documentStyleUpdates.font = default_font
                }
                if (premiumFonts.includes(state.title_font)) {
                    documentUpdates.title_font = default_font
                }
                if (premiumFonts.includes(wordbankStyle?.font)) {
                    wordbankStyleUpdate.font = default_font
                }
                if (premiumFonts.includes(state.student_info_font)) {
                    documentUpdates.student_info_font = default_font
                }
                if (premiumFonts.includes(state[state.entity_type]?.instruction?.style.font)) {
                    documentInstructionUpdates.font = default_font
                }

                // Check handwriting fonts
                let handwritingFonts = getters.handwritingFonts
                if (
                    premiumFonts &&
                    handwritingFonts.length &&
                    handwritingFonts.filter((p) => premiumFonts.includes(p)).length > 0
                ) {
                    dispatch('resetHandwritingFonts')
                }

                //fill-in-the-blank blank style
                const premiumFIBs = state.worksheet.body.items.filter(
                    (w) => w.type == 'fill_in_the_blank' && w.data.blank_style != 'underline',
                )
                for (let wItem of premiumFIBs) {
                    wItem.data.blank_style = 'underline'
                }

                // Number format
                if (documentStyle.number_format > 2) {
                    documentStyleUpdates.number_format = 1
                }

                documentUpdates.entity = {}

                //Bingo card count
                let card_max = rootState.user.ab_tests['premium-bingo-cards']
                if (state.bingo?.num_of_cards > card_max) {
                    documentUpdates.entity.num_of_cards = card_max
                }

                documentUpdates.entity.images = freeIcons(rootGetters[`document/${documentEntityType}Images`])
                documentUpdates.entity.inline_images = freeIcons(rootGetters[`document/${documentEntityType}InlineImages`])

                //run the updates
                dispatch('setDocument', documentUpdates)
                dispatch('setDocumentStyle', documentStyleUpdates)
                dispatch('setWordbankStyle', documentStyleUpdates)
                dispatch('setInstructionStyle', documentInstructionUpdates)
                dispatch('setWorksheetSetting', worksheetSettingUpdates)
                dispatch('storeDocumentState')

                //fire off an event that the document "remove premium features" has been fired
                EventApi.create_event('reverted-to-basic', state.id)
            },
        }
    }
}
