export const subjects = [
    'Art / Music',
    'English / Language Arts',
    'Foreign Languages',
    'Math',
    'Science',
    'Social Studies',
    'Other',
]

export const border_styles = ['solid', 'dashed', 'dotted', 'double', 'groove', 'ridge', 'inset', 'outset']

export const element_types = [
    {
        key: 'word_bank',
        name: 'Word Bank',
    },
    {
        key: 'instruction',
        name: 'Instruction Box',
    },
    {
        key: 'section_header',
        name: 'Section Header',
    },
    {
        key: 'basic_text',
        name: 'Basic Text',
    },
    {
        key: 'divider',
        name: 'Divider',
    },
    // {
    //
    //     key: 'blank_space',
    //     name: 'Blank Space',
    // },
    {
        key: 'blank_space',
        name: 'Blank Space',
    },
    {
        key: 'page_break',
        name: 'Page Break',
    },
]

export const worksheet_types = [
    {
        key: 'worksheet',
        name: 'Worksheet',
        type: 'worksheet',
        title: 'Multi-type Worksheet',
        instructions: 'Circle the correct answer for each question below.',
    },
]

export const worksheet_activities_types = [
    {
        key: 'open_response',
        name: 'Open Response',
        type: 'worksheet',
        title: 'Open Response Worksheet',
        instructions: 'Answer each question in the space provided.',
    },
    {
        key: 'multiple_choice',
        name: 'Multiple Choice',
        type: 'worksheet',
        title: 'Multiple Choice Worksheet',
        instructions: 'Circle the correct answer for each question below.',
    },
    {
        key: 'fill_in_the_blank',
        name: 'Fill in the Blank',
        type: 'worksheet',
        title: 'Fill in the Blank Worksheet',
        instructions: 'Fill in the blank with a word from the word bank.',
    },
    {
        key: 'checklist',
        name: 'Checklist',
        type: 'worksheet',
        title: 'Checklist Worksheet',
        instructions: 'Mark all the applicable answers.',
    },
    {
        key: 'matching',
        name: 'Matching',
        type: 'worksheet',
        title: 'Matching Worksheet',
        instructions: 'Match the word on the left side to its definition on the right side.',
    },
    {
        key: 'word_scramble',
        name: 'Word Scramble',
        type: 'worksheet',
        title: 'Word Scramble',
        instructions: 'Unscramble each word and write it in the blank provided.',
    },
    {
        key: 'handwriting',
        name: 'Handwriting',
        type: 'worksheet',
        title: 'Handwriting Practice',
        instructions: 'Use your best handwriting to write each word below.',
    },
]

export const bingo_types = [
    {
        key: 'bingo',
        name: 'Bingo Cards',
        type: 'bingo',
        title: "Let's Play Bingo!",
        instructions: 'Mark the word on your card when it is called out. If you have five squares in a row, say BINGO!',
    },
]

export const flashcard_types = [
    {
        key: 'flashcard',
        name: 'Flashcards',
        type: 'flashcard',
        title: 'Flashcards',
        instructions:
            'Cut out your flashcards along the dotted line. Then, fold each flashcard along the solid line and tape it closed. Use the remaining time to practice using your new flashcards!',
    },
]

export const zoomLevels = ['Fit', '25%', '33%', '50%', '75%', '100%', '125%', '150%', '200%', '250%', '300%']

export const premiumFonts = [
    'Abril Fatface',
    'Shadows Into Light',
    'Permanent Marker',
    'League Script',
    'Luckiest Guy',
    'Schoolbell',
    'Satisfy',
    'Special Elite',
    'Indi Flower',
    'Pacifico',
    'Sacramento',
    'Coming Soon',
    'Delius',
    'Didact Gothic',
    'Indie Flower',
    'Montserrat',
    'Oswald',
    'Play',
    'Poppins',
    'Questrial',
    'Roboto',
    'Tahoma',
    'Verdana',
    'Parisienne',
    'Imperial Script',
    'Pinyon Script',
    'Gwendolyn',
    'Mrs Saint Delafield',
    'Rochester',
    'Passions Conflict',
    'Dawning of a New Day',
    'Meddon',
    'MonteCarlo',
]

export const basicFonts = [
    'Arial',
    'Comic Sans MS',
    'Courier',
    'Georgia',
    'Lucida Typewriter',
    'Palatino',
    'Tahoma',
    'Times New Roman',
    'Trebuchet',
    'Verdana',
    'Catamaran',
    'Open Sans',
]

export const numberingFormats = [
    {
        name: '1',
        key: '1',
        isPremium: false,
    },
    {
        name: '1.',
        key: '2',
        isPremium: false,
    },
    {
        name: '1)',
        key: '3',
        isPremium: true,
    },
    {
        name: 'a',
        key: '4',
        isPremium: true,
    },
    {
        name: 'A',
        key: '5',
        isPremium: true,
    },
    {
        name: '<span style="font-family: Times New Roman;">I.</span>',
        key: '6',
        isPremium: true,
    },
    {
        name: 'None',
        key: '7',
        isPremium: true,
    },
    {
        name: '<span style="font-family: Times New Roman;">i.</span>',
        key: '8',
        isPremium: true,
    },
    {
        name: '<span style="font-size:0.5em; margin-bottom: 5px;">&#x2B24;</span>',
        key: '9',
        isPremium: true,
    },
]
