import BaseModule from '../base'
import { hasNonIconImages } from '../../../helpers/jsonObjectHelper'

export default class ImageModule extends BaseModule {
    state() {
        return {
            ...super.state(),
            loadingImage: false,
        }
    }

    actions() {
        return {
            ...super.actions(),
            setImage({ commit, dispatch }, payload) {
                commit('SET_IMAGES', payload)
                commit('UPDATE_IS_PRIVATE')

                if (this.state.document.is_inline_image) {
                    dispatch('setInlineImage', payload)
                }

                dispatch('storeDocumentState')
            },

            setImageId({ state, commit, dispatch }, payload) {
                commit('SET_IMAGE_ID', payload)

                if (this.state.document.is_inline_image) {
                    const image = state[state.entity_type].inline_images.find((i) => i.objectId == payload.newId)
                    if (image) {
                        dispatch('updateImage', image)
                    }
                }

                dispatch('storeDocumentState')
            },

            removeImage({ state, commit, dispatch }, payload) {
                commit('REMOVE_IMAGE', payload)
                commit('UPDATE_IS_PRIVATE')
                dispatch('storeDocumentState')
            },

            updateImage({ commit, dispatch }, payload) {
                commit('UPDATE_IMAGE', payload)
                dispatch('storeDocumentState')
            },

            updateImageZIndex({ commit, state }, payload) {
                const currentIndex = state[payload.entity].images.findIndex((i) => i.id === payload.id)
                if (payload.move === 'front' && currentIndex === 0) return
                if (payload.move === 'back' && currentIndex === state[payload.entity].images.length - 1) return

                const newIndex = payload.move === 'front' ? currentIndex - 1 : currentIndex + 1
                commit('MOVE_IMAGE', {
                    currentIndex: currentIndex,
                    newIndex: newIndex,
                    entity: payload.entity,
                })
            },
            setInlineImage({ commit, dispatch }, payload) {
                if (payload[0].entity === 'worksheet') {
                    commit('SET_INLINE_IMAGE', payload)
                } else if (payload[0].entity === 'bingo') {
                    commit('SET_BINGO_INLINE_IMAGE', payload)
                } else if (payload[0].entity === 'flashcard') {
                    commit('SET_FLASHCARD_INLINE_IMAGE', payload)
                }

                dispatch('storeDocumentState')
            },
            async removeInlineImage({ commit, dispatch }, payload) {
                commit('REMOVE_INLINE_IMAGE', payload)
                commit('UPDATE_IS_PRIVATE')
                dispatch('storeDocumentState')
            },
            setLoadingImage({ commit }, value) {
                commit('SET_LOADING_IMAGE', value)
            },
        }
    }

    mutations() {
        return {
            ...super.mutations(),
            MOVE_IMAGE(state, payload) {
                const image = state[state.entity_type].images.splice(payload.currentIndex, 1)[0]
                state[state.entity_type].images.splice(payload.newIndex, 0, image)
            },

            REMOVE_IMAGE(state, payload) {
                const index = state[state.entity_type].images.map((i) => i.objectId).indexOf(payload.id)

                state[state.entity_type].images.splice(index, 1)
            },

            SET_IMAGES(state, images) {
                const imageType = this.state.document.is_inline_image ? 'inline_images' : 'images'
                for (let i = 0; i < images.length; i++) {
                    images[i].color = typeof images[i].color === 'undefined' ? '#000000' : images[i].color
                    images[i].rotation = typeof images[i].rotation === 'undefined' ? '0' : images[i].rotation
                    images[i].flipHorizontal =
                        typeof images[i].flipHorizontal === 'undefined' ? false : images[i].flipHorizontal
                    images[i].flipVertical = typeof images[i].flipVertical === 'undefined' ? false : images[i].flipVertical
                }
                state[state.entity_type][imageType] = [...images, ...state[state.entity_type][imageType]]
            },

            SET_IMAGE_ID(state, payload) {
                const imageType = this.state.document.is_inline_image ? 'inline_images' : 'images'
                let images = state[state.entity_type][imageType]
                for (let i = 0; i < images.length; i++) {
                    if (images[i].objectId === payload.oldId) {
                        images[i].objectId = payload.newId
                        images[i].downloaded = true
                        break
                    }
                }
                state[state.entity_type][imageType] = images
            },

            UPDATE_IMAGE(state, { id, ...payload }) {
                const imageType = payload?.is_inline_image
                    ? 'inline_images'
                    : this.state.document.is_inline_image
                      ? 'inline_images'
                      : 'images'
                const targetImage = state[state.entity_type][imageType].find((i) => i.id === id)
                if (targetImage) {
                    Object.keys(payload).forEach((key) => {
                        targetImage[key] = payload[key]
                    })
                }
            },
            UPDATE_IS_PRIVATE(state) {
                state.is_private =
                    (state[state.entity_type].images.length > 0 && hasNonIconImages(state[state.entity_type].images)) ||
                    (state[state.entity_type].inline_images.length > 0 &&
                        hasNonIconImages(state[state.entity_type].inline_images))
                        ? 1
                        : 0
            },
            SET_INLINE_IMAGE(state, images) {
                for (let i = 0; i < images.length; i++) {
                    if (!images[i]) continue

                    const inline_images_upload = this.state.document.inline_images_upload

                    let widget = state.worksheet.body.items[inline_images_upload?.item_index]

                    if (!widget) return

                    switch (widget.type) {
                        case 'matching':
                        default:
                            let input
                            if (inline_images_upload.column === 'fake') {
                                input = widget.data.fakeAnswers[inline_images_upload?.input_index]
                            } else {
                                input = widget.data.pairs[inline_images_upload?.input_index]
                            }

                            if (!input) continue

                            if (inline_images_upload.column === 'term' || inline_images_upload.column === 'definition') {
                                state.worksheet.body.items[inline_images_upload.item_index].data.pairs[
                                    inline_images_upload.input_index
                                ][`${inline_images_upload.column}_image`] = images[i].id
                            } else if (inline_images_upload.column === 'fake') {
                                state.worksheet.body.items[inline_images_upload.item_index].data.fakeAnswers[
                                    inline_images_upload.input_index
                                ].image = images[i].id
                            }
                    }
                }
            },
            SET_BINGO_INLINE_IMAGE(state, images) {
                for (let i = 0; i < images.length; i++) {
                    if (!images[i]) continue

                    const inline_images_upload = this.state.document.inline_images_upload

                    const input = state.bingo.content.items[inline_images_upload?.input_index]

                    if (!input) continue

                    if (inline_images_upload.column === 'term') {
                        state.bingo.content.items[inline_images_upload.input_index].term_image = images[i].id
                    }
                }
            },
            SET_FLASHCARD_INLINE_IMAGE(state, images) {
                for (let i = 0; i < images.length; i++) {
                    if (!images[i]) continue

                    const inline_images_upload = this.state.document.inline_images_upload

                    const input = state.flashcard.content.items[inline_images_upload?.input_index]

                    if (!input) continue

                    if (inline_images_upload.column === 'term' || inline_images_upload.column === 'definition') {
                        state.flashcard.content.items[inline_images_upload.input_index][
                            `${inline_images_upload.column}_image`
                        ] = images[i].id
                    }
                }
            },
            REMOVE_INLINE_IMAGE(state, payload) {
                const index = state[state.entity_type].inline_images.map((i) => i.objectId).indexOf(payload.objectId)

                state[state.entity_type].inline_images.splice(index, 1)
            },
            SET_LOADING_IMAGE(state, value) {
                state.loadingImage = value
            },
        }
    }

    getters() {
        return {
            ...super.getters(),
            loadingImage: (state) => state.loadingImage,
        }
    }
}
